import { Stack } from "@mui/material";
import WebDialog from "../../components/common/WebDialog";
import WebInput from "../../components/common/WebInput";
import { Controller } from "react-hook-form";
import WebSelect from "../../components/common/WebSelect";
import useTestDriveForm from "../../hooks/useTestDriveForm";
import WebPrimaryButton from "../../components/common/WebPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { modelOptions, selectModelListState } from "../../store/models/selectors";
import SuccessModal from "../../components/common/SuccessModal";
import ErrorModal from "../../components/common/ErrorModal";
import { setModelStatus } from "../../store/models/actions";

interface TestDriveFormProps {
    onClose: () => void;
}

const TestDriveForm = ({ onClose }: TestDriveFormProps) => {
    const { control, isSubmitting, register, errors, submitHandler, submitStatus, serverError } = useTestDriveForm("Models");

    const options = useSelector(modelOptions);

    const dispatch = useDispatch();
    const handleClose = () => dispatch(setModelStatus("idle"));

    return (
        <>  
            <SuccessModal
                open={submitStatus === "success"}
                message='We have successfully received your application'
                callback={handleClose}
            />
            <ErrorModal open={submitStatus === "error"} message={serverError} callback={handleClose} />
            <WebDialog open onClose={onClose} title='Vehicles Test Drive'>
                <Stack component='form' gap={2.2} px={{ xs: 1, md: 4 }} mb={4} onSubmit={submitHandler}>
                    <Stack sx={{ flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                        <WebInput
                            fullWidth
                            {...register("firstName")}
                            placeholder='First Name'
                            error={!!errors?.firstName}
                            helperText={errors?.firstName?.message}
                            />
                        <WebInput
                            fullWidth
                            {...register("lastName")}
                            placeholder='Last Name'
                            error={!!errors?.lastName}
                            helperText={errors?.lastName?.message}
                        />
                    </Stack>
                    <WebInput 
                        fullWidth
                        {...register("email")}
                        placeholder="Email"
                        error={!!errors?.email}
                        helperText={errors?.email?.message}
                    />
                    <WebInput
                        placeholder="Phone number"
                        {...register("phoneNumber")}
                        error={!!errors?.phoneNumber}
                        helperText={errors?.phoneNumber?.message}
                    />
                    <Controller
                        control={control}
                        name='model'
                        render={({ field }) => (
                        <WebSelect
                            label='Model'
                            options={options}
                            {...field}
                            placeholder='Select preferred cars model'
                            {...register("model")}
                            error={!!errors?.model}
                            helperText={errors?.model?.message}
                        />
                        )}
                    />
                    <WebInput 
                        fullWidth
                        placeholder="Select preferred date and time"
                        type="datetime-local"
                        {...register("date")}
                        error={!!errors?.date}
                        helperText={errors?.date?.message}
                    />
                    <WebPrimaryButton 
                        type='submit' 
                        loading={isSubmitting} 
                        fullWidth
                    >
                        Submit
                    </WebPrimaryButton>
                </Stack>
            </WebDialog>
        </>
    );
};

export default TestDriveForm;

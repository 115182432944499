import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectModelListState } from "../store/models/selectors";
import { submitTestDriveForm } from "../store/models/actions";

const phoneRegExp = /^(09|\+639)\d{9}$/;

const testDriveSchema = yup
  .object({
    firstName: yup.string().required("First name is required").trim(),
    lastName: yup.string().required("Last name is required").trim(),
    email: yup.string().required("Email is required").email("Provide a valid email").trim(),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required"),
    model: yup.string().required("Model is required").trim(),
    date: yup.date().required("Provide a valid date"),
  })
  .required();

interface TestDriveFields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  model: string;
  date: Date;
}

const defaultTestDriveValues: TestDriveFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  model: "",
  date: new Date,
};

export default function useTestDriveForm(moduleName: string) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<TestDriveFields>({
    defaultValues: defaultTestDriveValues,
    resolver: yupResolver(testDriveSchema),
    mode: "onChange",
  });

  const { status, error } = useSelector(selectModelListState);
  const dispatch = useDispatch();

  const onSubmit = (formData: TestDriveFields) => {
    if (status === "submitting") return;

    dispatch(submitTestDriveForm(formData, moduleName, reset));
  };

  const submitHandler = handleSubmit(onSubmit);

  return {
    register,
    control,
    errors,
    submitHandler,
    isValid,
    isSubmitting,
    submitStatus: status,
    isSubmitSuccessful,
    serverError: error instanceof Error ? error.message : "Something went wrong :(",
  };
}
import { AxiosResponse } from "axios";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  LOAD_MODELS_DATA,
  SET_MODEL_STATUS,
  SET_SELECTED_MODEL,
  IModels,
  ModelListAction,
  SelectedModel,
  IModelTestDrive,
  SET_MODEL_ERROR,
} from "./types";

export const setModelStatus = (status: Status): ModelListAction => ({
  type: SET_MODEL_STATUS,
  payload: { status }
});

const setModelError = (error: Error): ModelListAction => ({
  type: SET_MODEL_ERROR,
  payload: { error },
});

const loadModelData = (data: IModels[]): ModelListAction => ({
  type: LOAD_MODELS_DATA,
  payload: { data },
});

export const setModelVariants = (variant: SelectedModel): ModelListAction => ({
  type: SET_SELECTED_MODEL,
  payload: { ...variant }
});

export const fetchModels = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setModelStatus("loading"));

    const response:AxiosResponse<any> = await apiClient.request({
      url: `${apiEndpoints.models}`,
    });

    dispatch(loadModelData(response.data)); 
  } catch (err: any) {
    dispatch(setModelStatus("error"));
    errorHandler(err, dispatch);
  }
};

export const submitTestDriveForm =
  (formData: IModelTestDrive, moduleName: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setModelStatus("submitting"));

      const { 
        firstName, 
        lastName, 
        email, 
        phoneNumber, 
        model,
        date 
      } = formData;

      const newFormData: Partial<IModelTestDrive> = {
        firstName,
        lastName,
        email,
        phoneNumber,
        model,
        date 
      };

      // call api here
      await apiClient.post(apiEndpoints.sendTestDriveDetails, {
        ...newFormData,
        module: moduleName,
      });

      dispatch(setModelStatus("success"));

      if (callback) callback();
    } catch (err: any) {
      let error = new Error("Server Error");
      if (err?.response?.data) {
        if (Array.isArray(err.response.data)) {
          error.message = err.response.data[0].message;
        } else {
          error.message = err.response.data.message;
        }
      }

      dispatch(setModelError(error));
    }
  };